* {
  box-sizing: border-box;
  margin: 0;
}

.App {
  text-align: center;
}

.grecaptcha-badge {
  bottom: 60px !important;
}

.swal2-container {
  font-family: "Barlow";
}

.swal2-styled.swal2-confirm {
  background-color: #002eff !important;
}

.swal2-styled.swal2-confirm.delete {
  background-color: #dc2020 !important;
}

.swal2-styled.swal2-cancel {
  background-color: #e5e5e5 !important;
  color: #191919 !important;
}
